import React, { Component } from 'react';
import PrestaBtn from './PrestaBtn';
import data from '../../data/services';

export default class PrestaList extends Component {
  render() { 
    return (
      <div className={this.props.className}>
        <div className="f-wrap f-center">
          <PrestaBtn className="mr10" item={data.code} onClick={(e) => this.props.onClick(e, data.code)} />
          <PrestaBtn className="mr10" item={data.condAcc} onClick={(e) => this.props.onClick(e, data.condAcc)} />
          <PrestaBtn className="mr10" item={data.condSup} onClick={(e) => this.props.onClick(e, data.condSup)} />
          <PrestaBtn className="mr10" item={data.pB} onClick={(e) => this.props.onClick(e, data.pB)} />
          <PrestaBtn className="mr10" item={data.boiteAuto} onClick={(e) => this.props.onClick(e, data.boiteAuto)} />
          <PrestaBtn className="mr10" item={data.pBSR} onClick={(e) => this.props.onClick(e, data.pBSR)} />
          <PrestaBtn className="mr10" item={data.p125} onClick={(e) => this.props.onClick(e, data.p125)} />
          {/* <PrestaBtn className="mr10" item={data.pA1} onClick={(e) => this.props.onClick(e, data.pA1)} />
          <PrestaBtn className="mr10" item={data.pA2} onClick={(e) => this.props.onClick(e, data.pA2)} /> */}
          <PrestaBtn className="mr10" item={data.postP} onClick={(e) => this.props.onClick(e, data.postP)} />
          <PrestaBtn className="mr10" item={data.ran} onClick={(e) => this.props.onClick(e, data.ran)} />
        </div>
      </div>
    );
  }
}
