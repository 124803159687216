import React from 'react';
import Nav from './Nav';
import InfoCovid from './InfoCovid';

function Header() {
  return (
    <div id="header" className="container-fluid p0 m0">
      {
        window.innerWidth < 992
          ? (
            <div>
              <Nav />
              <div className="separator" />
              
              <div className="container mb20">
                <InfoCovid />
              </div>
            </div>
          ) 
          : (
            <div>
              <div className="f-around w100 txt-l logo-title">
                <div className="txt-l w10rem">
                  <img
                    src={require('../../../assets/img/hcLogo.png')}
                    alt="Logo Hiriburu Conduite"
                    className="d-block logo p5"
                  />
                </div>
        
                <div className="w60 relative">
                  <div className="absolute title">
                    <h1 className="txt-bold txt-30 white">
                      Hiriburu Conduite
                    </h1>
                    <p className="txt-bold txt-14 desc">
                      AUTO/MOTO école Bayonne, St Pierre d'Irube, Cambo
                    </p>
                  </div>
                </div>
              </div>

              <Nav />

              <div className="separator" />
              
              <div className="container">
                <InfoCovid />
              </div>
            </div>
          )
      }
    </div>
  );
};

export default Header;
