import React from 'react';
import {withRouter} from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faHome, faCar, faEnvelope, faAward } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

function MainNav({location}) {
  return (
    <Navbar expand="lg" bg="light">
      <Navbar.Brand href="/" className="f d-lg-none .d-xl-block">
        <img src={require('../../../assets/img/hcLogo.png')} alt="logo" width="30" height="50" />
        <h1 className="txt-12 txt-bold blue pt5 ml5">Hiriburu Conduite</h1>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" className="txt-r pt5" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav fill={window.innerWidth < 992 ? false : true} className={window.innerWidth < 992 ? null : "justify-content-center w100"}>
          <Nav.Link className={location.pathname === '/' ? 'active navlink' : 'navlink'} href="/">
            <Icon icon={faHome} className="txt-12 mr5" />
            Accueil
          </Nav.Link>

          <Nav.Link className={location.pathname === '/prestations' ? 'active navlink' : 'navlink'} href="prestations">
            <Icon icon={faCar} className="txt-12 mr5" />
            Prestations
          </Nav.Link>

          <Nav.Link className={location.pathname === '/label' ? 'active navlink' : 'navlink'} href="label">
            <Icon icon={faAward} className="txt-12 mr5" />
            Charte Qualité
          </Nav.Link>

          <Nav.Link className={location.pathname === '/contact' ? 'active navlink' : 'navlink'} href="contact">
            <Icon icon={faEnvelope} className="txt-12 mr5" />
            Contact
          </Nav.Link>

          <Nav.Link className="navlink" href="https://www.facebook.com/Hiriburu-Conduite-1607180319335194/">
            <Icon icon={faFacebookF} className="txt-12 mr5" />
            Facebook
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default withRouter(MainNav);
