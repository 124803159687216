import React from 'react';
import {Figure} from 'react-bootstrap'

function PrestaBtn(props) {
  return (
    <div onClick={props.onClick} className={props.className}>
      <Figure className={'icon-btn pointer txt-c'}>
        <Figure.Image
          className="w80 h50 mt10"
          alt={props.item.title}
          src={props.item.icon}
        />
        <Figure.Caption className={'txt-9 txt-bold'}>
          {props.item.title}
        </Figure.Caption>
      </Figure>
    </div>
  );
};

export default PrestaBtn;
