import React, {Component} from 'react';
import {Card} from 'react-bootstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default class Presta extends Component {
  render() {
    let {className, item} = this.props;

    return (
      <Card className={className}>
        <Card.Header className={'f-row bg-' + item.bgColor + ' ' + item.color}>
          <Card.Img src={item.icon} className={'card-header-logo mt5'} />
          <Card.Title className="w100 ml20 mt5 txt-20">{item.title}</Card.Title>
          <Icon icon={faTimesCircle}  onClick={this.props.onClose} className="close-icon" />
        </Card.Header>
        <Card.Body>
          {
            window.innerWidth <= 992
            ? (
              <div>
                <img src={item.img} alt="presta" className="w100 mb20" />
                <div>{item.body}</div>
              </div>
            )
            : (
              <div className="f-between">
                <div className="w55 pr5">{item.body}</div>
                <div className="w45">
                  <img src={item.img} alt="presta" className="presta-img" />
                </div>
              </div>
            )
          }
          { item.more ? item.more : null }
        </Card.Body>
      </Card>
    );
  }
};
