import React, {Component} from 'react';
import {Card} from 'react-bootstrap';
import ActionBtn from './ActionBtn';

export default class Agency extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMapVisible: false,
      isMobile: window.innerWidth < 992
    };
  }

  onClickShowMap = (e) => {
    e.preventDefault();

    this.setState({
      isMapVisible: true,
    });
  }

  renderMobile = () => {
    let {city, img, street, postal, phone, email, accueil_times, driving_times, code_times, localisation} = this.props.agency;                         
    
    return (
      <Card className={this.props.className}>
        <Card.Title className="agency-title p10 txt-16">{city}</Card.Title>
        <Card.Body className="agency-body">
          <Card.Text className="blue txt-bold txt-c mb10">
            {street+ ' ' + postal + ' ' + city}
          </Card.Text>

          <Card.Img variant="top" src={img} className="w100 mb10" />

          <div>
            <Card.Text className="txt-bold">
              Accueil et Inscriptions: 
            </Card.Text>
            <Card.Text>
              {accueil_times}
            </Card.Text>

            <Card.Text className="txt-bold">
              Cours de code: 
            </Card.Text>
            <Card.Text>
              {code_times}
            </Card.Text>

            <Card.Text className="txt-bold">
              Cours de conduite: 
            </Card.Text>
            <Card.Text>
              {driving_times}
            </Card.Text>
          </div>

          <div className="w100 mb20">
            <ActionBtn type="phone" value={phone} width="w100 mt10" txt="txt-8" />
            <ActionBtn type="email" value={email} width="w100 mt10" txt="txt-8" />
          </div>

          <div className="mt10 d-block">
            <iframe
              title={city}
              src={localisation}
              className="w100 gmap"
              frameBorder="0"
              style={{border: 0}}
              allowFullScreen
            />
          </div>
        </Card.Body>
      </Card>
    );  
  }

  render() {
    let {city, img, street, postal, phone, email, accueil_times, driving_times, code_times, localisation} = this.props.agency;
    
    if (this.state.isMobile) {
      return this.renderMobile();
    }
    
    return (
      <Card className={this.props.className}>
        <Card.Title className="agency-title p10 txt-16">{city}</Card.Title>
        <Card.Body className="agency-body">
          <Card.Text className="blue txt-bold txt-c mb10">
            {street+ ' ' + postal + ' ' + city}
          </Card.Text>

          <div className="f-between f-wrap">
            <Card.Img variant="top" src={img} className="w48" />

            <div className="mt10 w48">
              <Card.Text className="txt-bold">
                Accueil et Inscriptions: 
              </Card.Text>
              <Card.Text>
                {accueil_times}
              </Card.Text>

              <Card.Text className="txt-bold">
                Cours de code: 
              </Card.Text>
              <Card.Text>
                {code_times}
              </Card.Text>

              <Card.Text className="txt-bold">
                Cours de conduite: 
              </Card.Text>
              <Card.Text>
                {driving_times}
              </Card.Text>                  
              <div>
                <ActionBtn type="phone" value={phone} txt="txt-10" />
                <ActionBtn type="email" value={email} txt="txt-10" />
              </div>
            </div>
          </div>

          <div className="mt10 d-block">
            <iframe
              title={city}
              src={localisation}
              className="w100 gmap"
              frameBorder="0"
              style={{border: 0}}
              allowFullScreen
            />
          </div>
        </Card.Body>
      </Card>
    );
  }
}