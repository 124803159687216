import React, {Component} from 'react';
import {Jumbotron} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import Helmet from '../components/shared/Helmet';
import Agency from '../components/Agency';
import PrestaList from '../components/PrestaList';
import Presta from '../components/Presta';
import Slider from '../components/Slider';
import data from '../../data/agencies';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      presta: null,
    };
  }

  onOpenPresta = (e, item) => {
    e.preventDefault();

    this.setState({
      presta: item,
    });
  }

  onClosePresta = (e) => {
    e.preventDefault();

    this.setState({
      presta: null,
    });
  }

  render() {
    return (
      <div id="home" className="container mb40">
        <Helmet
          title="Acceuil"
          address={this.props.location.pathname}
        />

        <Slider className="w100" />

        <Jumbotron className="txt-12 mt20">
          <h1 className="mb10">Hiriburu Conduite</h1>
          <p>
            <span className="txt-bold">Passer son permis sans se ruiner</span>, c’est désormais possible grâce aux équipes 
            de l’auto-école <span className="txt-bold">Hiriburu Conduite</span> qui pour garantir une formation efficace et 
            rapide, met les meilleures moyens au service de ses éléves.
          </p>
          <p>
            Dans la région de <span className="txt-bold">Bayonne Cambo</span>, nous mettons tout à votre disposition 
            pour garantir votre succès lors de l’examen du permis de conduire.
          </p>
          <Link to="more" className="mt20">Cliquez ici pour en savoir plus ...</Link>
        </Jumbotron>

        <div className="txt-l mt40">
          <h1>Nos Prestations</h1>
          <PrestaList
            className={this.state.presta && window.innerWidth <= 992 ? 'hidden' : 'mt20'}
            onClick={this.onOpenPresta}
          />
          
          {
            this.state.presta
              ? (
                <Presta item={this.state.presta} className="mt20" onClose={this.onClosePresta} />
              )
              : null
          }
        </div>
  
        <div className="txt-l mt40">
          <h1>Nos 2 Agences</h1>
          <div className="mt10 mb10 txt-12">
            <p>
              Le secrétariat de Cambo-les-bains est ouvert le mardi de 10h-12h 14h-18h30 joignable au 
              <a href={'tel:' + data.shared.phone} className="ml5">{data.shared.phone}</a>
            </p>
            <p>
              En dehors des heures d'ouvertures du secrétariat contactez-nous à 
              <a href={'mailto:' + data.shared.email} className="ml5">{data.shared.email}</a>
            </p>
          </div>
          <Agency agency={data.kanbo} className="w100 mt20" />
          <Agency agency={data.hiriburu} className="w100 mt40" />
        </div>
      </div>
    );
  }
};
