// DOCUMENTATION: https://github.com/YIZHUANG/react-multi-carousel
import React, {Component} from 'react';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1224 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1224, min: 484 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 484, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const items = [
  require('../../assets/img/slider/slide-01.jpg'),
  require('../../assets/img/slider/slide-02.jpg'),
  require('../../assets/img/slider/slide-pub1.jpg'),
  require('../../assets/img/slider/slide-03.jpg'),
  require('../../assets/img/slider/slide-04.jpg'),
  require('../../assets/img/slider/slide-pub2.png'),
  require('../../assets/img/slider/slide-09.jpg'),
  require('../../assets/img/slider/slide-06.jpg'),
  require('../../assets/img/slider/slide-pub3.png'),
  require('../../assets/img/slider/slide-07.jpg'),
  require('../../assets/img/slider/slide-08.jpg'),
  require('../../assets/img/slider/slide-pub4.png'),
]

export default class Slider extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
          keyBoardControl={false}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {
            items.map((item, i) => (
              <div key={i}>
                <img src={item} alt="slide" className="w100 slide p5" />
              </div>
            ))
          }
        </Carousel>
      </div>
    );
  }
};
