import React, {useState} from 'react';
import {Jumbotron} from 'react-bootstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faVirus, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function InfoCovid() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Jumbotron className="mt20 covid-box">
      <div className="covid-title-box">
        <h1 className="covid-title">
          {
            window.innerWidth < 992
            ? null
            : <Icon icon={faVirus} className="mr10" />
          }
          INFO COVID19
        </h1>

        <Icon 
          icon={isOpen === false ? faChevronDown : faChevronUp} 
          className="chevron" 
          onClick={() => setIsOpen(!isOpen)} 
        />
      </div>

      {
        isOpen === false 
          ? null 
          : (
            <div className="mt20">
              <p>
                <span className="txt-underline txt-bold">Nouvelles dispositions:</span>
              </p>
              <p>
                <span className="txt-i">articles 4 et 35 du décret n°2020-1310 du 29 octobre 2020 modifié ce jour</span>
              </p>
              <ul>
                <li>
                  <span className="txt-bold">Port de masque obligatoire.</span>
                </li>
                <li>
                  Les auto-écoles, en tant qu'établissements, ne peuvent recevoir du public <span className="txt-bold">à compter de 18h.</span>
                </li>
                <li>
                  1 seule personne<span className="txt-bold">1 seule personne</span> pourra être l'accueil à la fois et prise de solution hydroalcoolique obligatoire au distributeur,
                  pour toutes personnes entrant dans l'établissement.
                </li>
              </ul>
              <p>
                <span className="txt-underline txt-bold">Pour le code:</span> les cours théoriques en présentiel restent <span className="txt-bold">interdits jusqu'à nouvel ordre.</span>
              </p>
              <p>
                <span className="txt-underline txt-bold">Pour la conduite:</span> autorisation pour l’élève ou l’enseignant de la conduite à se déplacer après 18h à l'occasion d'un cours de conduite ou pour rentrer à son domicile à l'issue d'un tel cours.
              </p>
              <p>
                <span className="txt-underline txt-bold">Pour les formations:</span> Prendre rendez vous au sécrétariat au 09 54 72 03 54
              </p>
              <p>
                Merci pour votre compréhension, nous vous assurons tout notre engagement pour sortir le plus rapidement possible de cette situation. <br />
                Prenez soin de vous .
              </p>
              <p>
                Cordialement,
              </p>
              <p>
                La Direction de l'auto-école Hiriburu Conduite.
              </p>
            </div>
          )
      } 

    </Jumbotron>
  );
}

export default InfoCovid;
