import React from 'react';

function Facebook({className}) {
  return (
    <div className={className}>
      <div
        className="fb-page"
        data-href="https://www.facebook.com/Hiriburu-Conduite-1607180319335194/"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="false"
      >
        <blockquote
          cite="https://www.facebook.com/Hiriburu-Conduite-1607180319335194/"
          className="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/Hiriburu-Conduite-1607180319335194/">
            Hiriburu Conduite
          </a>
        </blockquote>
      </div>
    </div>
  );
};

export default Facebook;
