import React from 'react';
import {Link} from 'react-router-dom';

function Footer() {
  let dateYear = new Date().getFullYear();

  return (
    <div id="footer">
      <div className="container f-row f-around txt-c pt10">
        <Link to="/" className="white txt-deco-no txt-8"><h5>{'Hiriburu Conduite - ' + dateYear}</h5></Link>
        <Link to="/legals" className="white txt-deco-no txt-8 mt4">Mentions légales</Link>
      </div>
    </div>
  );
};

export default Footer;
