import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import Home from './pages/Home';
import Prestations from './pages/Prestations';
import Contact from './pages/Contact';
import QualityLabel from './pages/QualityLabel';
import Legals from './pages/Legals';
import More from './components/More';

function App() {
  return (
    <div>
      <Router id="App">
        <Header />
        <Switch>
          <Route path="/accueil" component={Home} />
          <Route path="/prestations" component={Prestations} />
          <Route path="/contact" component={Contact} />
          <Route path="/label" component={QualityLabel} />
          <Route path="/legals" component={Legals} />
          <Route path="/more" component={More} />
          <Route path="/" component={Home}/>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
