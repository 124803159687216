import React from 'react';
import Agency from '../components/Agency';
import Facebook from '../components/Facebook';
import data from '../../data/agencies';
import Helmet from '../components/shared/Helmet';

function Contact(props) {
  return (
    <div id="contact" className="container mt20 mb20 txt-l txt-12">
      <Helmet
        title="Contact"
        address={props.location.pathname}
      />

      <h1>Contact</h1>
      <p>
        Le secrétariat de Cambo-les-bains est ouvert le mardi de 10h à 12h et de 14h à 18h30, joignable au 
        <a href={'tel:' + data.shared.phone} className="ml5">{data.shared.phone}</a>
        <br />
        En dehors des heures d'ouvertures du secrétariat contactez-nous à 
        <a href={'mailto:' + data.shared.email} className="ml5">{data.shared.email}</a>
      </p>

      <Facebook className="mt20 txt-c" />

      <div className="mt20">
        <Agency agency={data.kanbo} className="w100 mt20 mb40" />
        <Agency agency={data.hiriburu} className="w100 mt20 mb20" />
      </div>
    </div>
  );
};

export default Contact;
