import React, { Component } from 'react';
import Helmet from '../components/shared/Helmet';

export default class Legals extends Component {
  render() {
    return (
      <div className="container txt-l mt20 mb40">
        <Helmet
          title="Mentions légales"
          address={this.props.location.pathname}
        />
        
        <h1 className="mb40">Mentions Légales </h1>
        <div>
          <h2>Confidentialité</h2>
          <p>
            Hiriburu Conduite n'enregistre pas d'informations personnelles permettant 
            l'identification, à l'exception des formulaires que l'utilisateur est libre 
            de remplir. Ces informations ne seront pas utilisées sans votre accord, nous 
            les utiliserons seulement pour vous adresser des courriers, des brochures, 
            des devis ou vous contacter.
            <br />
            <br />
            Les informations recueillies sur les sites bénéficient de la protection de 
            la loi "Informatique et Libertés" n° 78-17 du 06 janvier 1978. Elles bénéficient 
            d'un droit d'accès, de rectification, d'opposition à communication et de suppression 
            sur simple demande à Hiriburu Conduite, 9 Avenue Harrokan, 64990 SAINT PIERRE D'IRUBE.
            <br />
            <br />
            Hiriburu Conduite pourra procéder à des analyses statistiques sans que celles-ci soient 
            nominatives et pourra en informer des tiers (organismes d'évaluation de fréquentation) 
            sous une forme résumée et non nominative.
          </p>
          
          <h2>Règlement général sur la protection des données (RGPD)</h2>
          <p>
            Hiriburu Conduite s'engage à ce que la collecte et le traitement de vos données, 
            effectués à partir du site 
            <a href="/" className="ml2">hiriburu-conduite.com</a>, 
            soient conformes au règlement général sur la protection des données (RGPD).
            Chaque formulaire ou téléservice limite la collecte des données personnelles au strict 
            nécessaire (minimisation des données) et indique notamment :
          </p>

          <ul>
            <li>
              quels sont les objectifs du recueil de ces données,
            </li>
            <li>
              si ces données sont obligatoires ou facultatives pour la gestion de votre demande,
            </li>
            <li>
              qui pourra en prendre connaissance (uniquement Hiriburu Conduite en principe, sauf précision 
              dans le formulaire lorsqu'une transmission à un tiers est nécessaire à la gestion de votre demande),
            </li>
          </ul>

          <p>
            Les données personnelles recueillies dans le cadre des services proposés sur 
            <a href="/" className="ml2 mr2">hiriburu-conduite.com</a> 
            sont traitées selon des protocoles sécurisés et permettent à Hiriburu Conduite de gérer les demandes 
            reçues dans ses applications informatiques.
          </p>
          <p>
            Pour toute information ou exercice de vos droits Informatique et Libertés sur les traitements de 
            données personnelles gérés par Hiriburu Conduite, vous pouvez contacter Hiriburu Conduite et 
            éventuellement son délégué à la protection des données (DPO), si distingué.
          </p>
        </div>

        <div>
          <h2>Utilisation de cookies</h2>
          <p>La gestion des commandes nécessite l'utilisation de cookies. Des informations non personnelles 
            sont enregistrées par ce système de cookies (fichiers texte utilisés pour reconnaître un utilisateur 
            et ainsi faciliter son utilisation du site). Ceux-ci n'ont aucune signification en dehors de leur 
            utilisation sur le site 
            <a href="/" className="ml2">hiriburu-conduite.com</a>.
          </p>
          
          <h2>Liens hypertexte</h2>
          <p>Hiriburu Conduite ne contrôle pas les sites en connexion avec le sien, et ne saurait donc être 
            responsable de leur contenu. Les risques liés à l'utilisation de ces sites incombent pleinement 
            à l'utilisateur. Il se conformera à leurs conditions d'utilisation.
            </p>
          
          <h2>Éditeur</h2>
          <p>
            Hiriburu Conduite
            <br />
            RCS  : 42377637600026
            <br />
            Siège social : 9 Avenue Harrokan, 64990 SAINT PIERRE D'IRUBE.
            <br />
            N° de téléphone : 09 54 72 03 54
          </p>
          
          <h2>Directeur de la publication</h2>
          <p>Madame MICHELE PIERRETTE DURRUTY</p>
          
          <h2>Hébergement</h2>
          <p>
            <a href="https://www.ovh.com">OVH</a>
          </p>
          <br />
        </div>
      </div>
    );
  }
}