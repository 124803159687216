import React from 'react';
import Helmet from 'react-helmet';

const appHelmet = ({title, address}) => (
  <Helmet>
    <title>Hiriburu Conduite</title>
    <link rel="canonical" href={'https://hiriburu-conduite.com' + address} />
    <meta lang="fr" name="keywords" content="auto-école Bayonne,auto-école Anglet,auto-école Cambo les Bains,auto-école Saint-Pierre-D’irube,école de conduite Bayonne,école de conduite Anglet,école de conduite Cambo les Bains,école de conduite Saint-Pierre-D’irube,remise à niveau conduite Bayonne,remise à niveau conduite Anglet,remise à niveau conduite Cambo les Bains,remise à niveau conduite Saint-Pierre-D’irube,formation accélérée conduite Bayonne,formation accélérée conduite Anglet,formation accélérée conduite Cambo les Bains,formation accélérée conduite Saint-Pierre-D’irube,conduite accompagnée Bayonne,conduite accompagnée Anglet,conduite accompagnée Cambo les Bains,conduite accompagnée Saint-Pierre-D’irube" />
    <meta name="description" content="Passez votre permis de conduire avec l’auto-école Hiriburu Conduite à Saint-Pierre-D’irube, Cambo et Bayonne." />
    <meta name="twitter:description" content="Passez votre permis de conduire avec l’auto-école Hiriburu Conduite à Saint-Pierre-D’irube, Cambo et Bayonne." />
    <meta name="twitter:title" content={'Hiriburu Conduite | ' + title} />
    <meta name="twitter:image" content={require('../../../assets/img/hcLogo.png')} />
    <meta property="og:locale" content="fr_FR" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={'Hiriburu Conduite | ' + title} />
    <meta property="og:description" content="Passez votre permis de conduire avec l’auto-école Hiriburu Conduite à Saint-Pierre-D’irube, Cambo et Bayonne." />
    <meta property="og:url" content="https://hiriburu-conduite.com" />
    <meta property="og:site_name" content="Hiriburu Conduite" />
    <meta property="og:image" content={require('../../../assets/img/hcLogo.png')} />
    <meta property="og:image:secure_url" content={require('../../../assets/img/hcLogo.png')} />
  </Helmet>
)

export default appHelmet;