import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import Helmet from '../components/shared/Helmet';

import PDF_0 from '../../assets/doc/charte_qualité_permis_1euro.pdf';
import PDF_1 from '../../assets/doc/c1.1.pdf';
import PDF_2 from '../../assets/doc/c1.4.pdf';
import PDF_3 from '../../assets/doc/c1.5.1.jpg';
import PDF_4 from '../../assets/doc/c1.5.2.jpg';
import PDF_5 from '../../assets/doc/c2.2.1.pdf';
import PDF_6 from '../../assets/doc/c2.2.2.pdf';
import PDF_7 from '../../assets/doc/c2.3.pdf';
import PDF_8 from '../../assets/doc/c3.1.pdf';
import PDF_9 from '../../assets/doc/c5.1.pdf';
import PDF_10 from '../../assets/doc/c5.2.pdf';

function QualityLabel(props) {
  return (
    <div id="label-quality">
      <Helmet
        title="Charte qualité"
        address={props.location.pathname}
      />

      <div className="container mt20 mb40">      
        <h1>Charte Qualité</h1>
        <div className="f-row f-wrap mt20 mb20">
          <img src={require('../../assets/img/photos/1euro.png')} alt="logo label qualité" className="logo-label d-block float-r" />
          
          <p className="ml20 mt10 txt-bold valign-m w70">
            L’État a décidé la création d’un label qui repose sur une démarche volontaire des écoles de conduite. Ce label validé
            par le Conseil nationale de l’emploi, de la formation et de l’orientation professionnelles (CNEFOP) doit permettre aux
            écoles de conduite de répondre aux six critères suivants:
          </p>
        </div>
      
        <ul className="description">
          <li>
              L’identification précise des objectifs de la formation et son adaptation au public formé
          </li>
          <li>
            L’adaptation des dispositifs d’accueil, de suivi pédagogique et d’évaluation aux publics de stagiaires
          </li>
          <li>
            L’adéquation des moyens pédagogiques, techniques et d’encadrement à l’offre de formation
          </li>
          <li>
            La qualification professionnelle et la formation continue des personnels chargés des formations
          </li>
          <li>
            Les conditions d’information du public sur l’offre de formation, ses délais d’accès et les résultats obtenus
          </li>
          <li>
            La prise en compte des appréciations rendues par les stagiaires
          </li>
        </ul>

        <div>
          
          <a href={PDF_0} target="_blank" rel="noopener noreferrer" className="f-row m20 txt-16">
            <div className="w5 m5">
              <img src={require('../../assets/img/PDF_file.svg')} alt="logo pdf" className="w100"/>
            </div>
            <span className="mt10">Consulter la charte qualité</span>
          </a>
        </div>
        
        <Jumbotron className="mt40">
          <h2>Critères</h2>
          <div className={window.innerWidth <= 992 ? '' : 'f-row f-wrap'}>
            <ul className={window.innerWidth <= 992 ? 'p0 w100' : 'p0 w50'}>
              <li>
                <a href={PDF_1} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 1.1
                </a>
                Evaluation de départ
              </li>
              <li>
              <a href={PDF_2} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 1.4 et 4.1
                </a>
                Liste des formateurs et du personnel, cours théoriques
              </li>
              <li>
              <a href={PDF_3} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 1.5 cas n°1
                </a>
                Post Permis
              </li>
              <li>
              <a href={PDF_4} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 1.5 cas n°2
                </a>
                Post Permis
              </li>
              <li>
              <a href={PDF_5} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 2.2
                </a>
                Enjeux permis de conduire
              </li>
            </ul>

            <ul className={window.innerWidth <= 992 ? 'p0 w100' : 'p0 w50'}>
              <li>
              <a href={PDF_6} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 2.2
                </a>
                Enjeux permis moto
              </li>
              <li>
              <a href={PDF_7} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 2.3
                </a>
                Réglèment intérieur
              </li>
              <li>
              <a href={PDF_8} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 3.1
                </a>
                Parcour de formation
              </li>
              <li>
              <a href={PDF_9} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 5.1
                </a>
                La conduite accompagnée
              </li>
              <li>
              <a href={PDF_10} target="_blank" rel="noopener noreferrer" className="mr5">
                  Critère 5.2
                </a>
                Piste moto
              </li>
            </ul>
          </div>
        </Jumbotron>
      </div>
    </div>
  );
}

export default QualityLabel;
