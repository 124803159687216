import React, { Component } from 'react';
import {Card, Button} from 'react-bootstrap';
import Facebook from './Facebook';

const isMobile = document.documentElement.clientWidth < 992;

export default class More extends Component {
  render() {
    return (
      <div className="container txt-l mt40 mb40">
        <h1>En savoir plus</h1>
        <div className="txt-l mt20">
          <h3 className="green mb10">FORMEZ- VOUS CHEZ UN PROFESSIONNEL DE LA CONDUITE</h3>
          <p>
            Vous avez besoin de votre permis rapidement ? Optez pour la formation accélérée à la conduite.
          </p>
          <p>
            Notre formation accélérée à la conduite vous le permet...
          </p>

          <div className={isMobile < 992 ? 'mt20' : 'f-between mt20'}>
            <Card className={isMobile < 992 ? 'w100' : 'w48'}>
              <Card.Img variant="top" src={require('../../assets/img/photos/moto1.jpg')} />
              <Card.Body>
                <Card.Title>QU’EST CE QUE LA FORMATION ACCELLÉRÉE À LA CONDUITE ?</Card.Title>
                <Card.Text>
                  La formation accélérée à la conduite est une formation intense qui allie la théorie 
                  avec les heures de codes et la pratique avec les heures de conduites. L’idée est que 
                  vous soyez prêt à passer et réussir l’examen du permis très rapidement.
                </Card.Text>
                <Card.Text>
                  Chez Hiriburu Conduite grâce à des moniteurs qualifiés et à plus de 30 ans d’expérience 
                  dans le domaine de l’apprentissage de la conduite, nous vous proposons une formation 
                  accélérée efficace. Nos moniteurs seront à votre écoute et vous accompagneront dans votre 
                  apprentissage, ils vous aideront à corriger vos erreurs rapidement de manière à que vous 
                  vous puissiez être prêts pour l’examen dans les plus brefs délais.
                </Card.Text>
                <Card.Title>POURQUOI FAIRE CONFIANCE À L’AUTO-ÉCOLE HIRIBURU CONDUITE ?</Card.Title>
                <ul>
                  <li>30 ans d’expérience au service des élèves</li>
                  <li>Des moniteurs dédiés à la progression des élèves</li>
                  <li>Une progression rapide</li>
                  <li>Des tarifs compétitifs</li>
                </ul>
              </Card.Body>
            </Card>

            <Card className={isMobile < 992 ? 'w100' : 'w48'}>
              <Card.Img variant="top" src={require('../../assets/img/photos/cars5.jpg')} />
              <Card.Body>
                <Card.Title>UNE SOLUTION ÉCONOMIQUE</Card.Title>
                <Card.Text>
                  La formation accélérée est idéale pour les élèves qui n’ont pas le temps et ont besoin de 
                  leur permis rapidement. C’est aussi une solution économique car, grâce à la formation de 
                  nos moniteurs, vous serez prêt à passer le permis rapidement et limiterait le nombre d’heures 
                  de cours dont vous avez besoin.
                </Card.Text>
                <Card.Text>
                  La formation accélérée est spécialement indiquée pour les élèves qui sont prêt à s’impliquer 
                  à fond sur une courte période. Elle demande beaucoup d’implication pour porter ces fruits.
                </Card.Text>
                <Card.Title>PRENEZ CONTACT AVEC NOUS</Card.Title>
                <Card.Link href="/contact">
                  <Button variant="success" className="w100 mt20">CONTACT</Button>
                </Card.Link>
                <Card.Title className="mt20">ET SUIVEZ NOTRE ACTUALITE SUR FACEBOOK</Card.Title>
                <div className="txt-c mt20">
                  <Facebook />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="txt-l mt40">
          <h3 className="green mb10">PRENEZ LA ROUTE EN TOUTE SÉCURITÉ</h3>
          <p>
            Passer son permis sans se ruiner, c’est désormais possible 
            grâce aux équipes de l’auto-école Hiriburu Conduite.
          </p>
          <p>
            Après 30 ans d’expérience dans le secteur, Mme Durruty a créé en 2011 
            l’auto-école Hiriburu Conduite. Avec ses équipes, elle met son savoir-faire 
            au service d’une formation à la conduite rapide et efficace.
          </p>

          <div className={isMobile < 992 ? 'mt20' : 'f-between mt20'}>
            <Card className={isMobile < 992 ? 'w100' : 'w48'}>
              <Card.Img variant="top" src={require('../../assets/img/photos/autoecole1.jpg')} />
              <Card.Body>
                <Card.Title>UNE FORMATION À LA CONDUITE PERSONNALISÉE</Card.Title>
                <Card.Text>
                  L’auto-école Hiriburu Conduite prés de Bayonne met à votre service des formateurs 
                  expérimentés pour vous garantir une formation à la conduite rapide et efficace.
                </Card.Text>
                <Card.Text>
                  Notre équipe est à l’écoute de chacun des élèves et met en place une pédagogie 
                  adaptée à chaque profil. Vous avez ainsi l’assurance d’avoir une formation qui 
                  s’adapte à votre personnalité mais aussi à vos  contraintes d’horaires et qui vous 
                  garantie une apprentissage rapide et efficace de la conduite.
                </Card.Text>
                <Card.Text>
                  Grâce à notre savoir-faire, nous nous engageons à vous former à toutes les réglées 
                  de sécurité.
                </Card.Text>
                <Card.Text>
                  UNE BONNE FORMATION À LA CONDUITE VOUS DONNE LA GARANTIE DE ROULER EN TOUTE SÉCURITÉ
                </Card.Text>
                <Card.Title>NOS AVANTAGES :</Card.Title>
                <ul>
                  <li>Des moniteurs qualifiées</li>
                  <li>Une formation adaptée à votre rythme d’apprentissage</li>
                  <li>Des formations pour plusieurs types de permis</li>
                </ul>
              </Card.Body>
            </Card>

            <Card className={isMobile < 992 ? 'w100' : 'w48'}>
              <Card.Img variant="top" src={require('../../assets/img/photos/autoecole2.jpg')} />
              <Card.Body>
                <Card.Title>UNE FORMATION À PLUSIEURS TYPES DE PERMIS</Card.Title>
                <Card.Text>
                  L’auto-école Hiriburu Conduite propose des formations aux codes, à la conduite et 
                  à différents types de permis.
                </Card.Text>
                <Card.Text>
                  Grâce à nos moniteurs spécialisés, nous vous accompagnons pour votre permis B voiture, 
                  votre permis AM (pour les petits cylindres), permis motos ainsi que pour les remises à 
                  niveau ou les conduites accompagnées.
                </Card.Text>
                <Card.Text>
                  L’école de conduite Hiriburu vous propose également des formations accélérées pour que 
                  la formation à la conduite soit plus économique.
                </Card.Text>
                <Card.Text>
                  GRÂCE AUX FORMATIONS ACCÉLÉRÉES, LE PERMIS DEVIENT UNE FORMATION ÉCONOMIQUE
                </Card.Text>
                <Card.Title>PRENEZ CONTACT AVEC NOUS</Card.Title>
                <Card.Link href="/contact">
                  <Button variant="success" className="w100 mt20">CONTACT</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="txt-l mt40">
          <h3 className="green mb10">GARANTISSEZ VOTRE RÉUSSITE AU PERMIS DE CONDUIRE</h3>
          <p>
            Pour garantir une formation efficace et rapide, l’école de conduite 
            Hiriburu Conduite met les meilleures moyens au service de ses éléves.
          </p>
          <p>
            Dans la région de Bayonne et d’Anglet, nous mettons tout à votre 
            disposition pour garantir votre succès lors de l’examen du permis de conduire.
          </p>

          <div className={isMobile < 992 ? 'mt20' : 'f-between mt20'}>
            <Card className={isMobile < 992 ? 'w100' : 'w48'}>
              <Card.Img variant="top" src={require('../../assets/img/photos/autoecole3.jpg')} />
              <Card.Body>
                <Card.Title>DES TARIFS COMPÉTITIFS POUR UNE FORMATION EFFICACE</Card.Title>
                <Card.Text>
                  Grâce à nos formations efficaces et rapides, qui allient le théorique avec les heures 
                  de codes ouverte 3 jours par semaine et les heure pratique grâce à notre équipe de 
                  moniteurs qualifiés, la formation à la conduite se révélera être économique.
                </Card.Text>
                <Card.Text>
                  Les moniteurs d’Hiriburu Conduite sont tous expérimentés et vous garantissent une 
                  formation efficace et adaptée à votre système d’apprentissage. Ainsi, dès 20 heures 
                  de conduite, vous serez en mesure de présenter l’examen du permis de conduire dans 
                  les meilleures conditions et avec les meilleures chances de réussite.
                </Card.Text>
                <Card.Text>
                  NOUS PROPOSONS DES HORAIRES DE COURS DE FLEXIBLE POUR S’ADAPTER À L’AGENDA DE NOS ÉLÉVES
                </Card.Text>
                <Card.Title>NOS PETIS PLUS :</Card.Title>
                <ul>
                  <li>Des prix compétitifs</li>
                  <li>Des moniteurs expérimentés</li>
                  <li>Des classes de code 3 fois par semaine</li>
                </ul>
              </Card.Body>
            </Card>

            <Card className={isMobile < 992 ? 'w100' : 'w48'}>
              <Card.Img variant="top" src={require('../../assets/img/photos/autoecole4.jpg')} />
              <Card.Body>
                <Card.Title>DES OUTILS ADAPTÉS À UNE FORMATION RAPIDE</Card.Title>
                <Card.Text>
                  Pour vous garantir une formation optimale et votre réussite à l’examen du permis de conduite, 
                  l’école de conduite Hiriburu Conduite met les meilleurs outils à votre service pour garantir 
                  votre réussite. Nos formules incluent des cours de code illimités, un livret d’accompagnement, 
                  un livret de code. En ce qui concerne la partie pratique, notre flotte de voiture est 
                  spécialement adaptée à l’apprentissage de la conduite, et nos véhicules sont très régulièrement 
                  entretenus pour garantir un succès rapide.
                </Card.Text>
                <Card.Text>
                  Nous vous proposons aussi des formations de conduite accompagnée.
                </Card.Text>
                <Card.Text>
                  NOUS METTONS À VOTRE DISPOSITION TOUS LES OUTILS NÉCESSAIRES À VOTRE RÉUSSITE
                </Card.Text>
                <Card.Title>PRENEZ CONTACT AVEC NOUS</Card.Title>
                <Card.Link href="/contact">
                  <Button variant="success" className="w100 mt20">CONTACT</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
