import React, { Component } from 'react';
import {Jumbotron} from 'react-bootstrap';

import Helmet from '../components/shared/Helmet';
import PrestaList from '../components/PrestaList';
import Presta from '../components/Presta';
import More from '../components/More';

import data from '../../data/services';

export default class Prestations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      presta: null,
    };
  }

  onClickOpenPresta = (e, item) => {
    e.preventDefault();
    this.setState({
      presta: item,
    });
  }

  onClickClosePresta = (e) => {
    e.preventDefault();
    this.setState({
      presta: null,
    });
  }

  render() {
    return (
      <div className="container txt-l mt20">
        <Helmet
          title={this.state.presta ? 'Prestations | ' + this.state.presta.title : 'Prestations'}
          address={this.props.location.pathname}
        />

        <h1>Prestations</h1>

        {
          !this.state.presta
            ? <blockquote className="txt-i ml10">Cliquer sur la prestation de votre choix</blockquote>
            : null
        }
        
        <div className="mt20 mb40">
          <PrestaList
            className={this.state.presta && window.innerWidth <= 992 ? 'hidden' : 'mt20'}
            onClick={this.onClickOpenPresta}
          />

          {
            this.state.presta
              ? (
                <Presta item={this.state.presta}  onClose={this.onClickClosePresta} className="mt20" />
              )
              : null
          }
        </div>
        <Jumbotron>
          <h3>PIECES A FOURNIR POUR TOUTE INSCRIPTION :</h3>
          <ul>
            {
              data.inscriptionDocs.map((doc, i) => (
              <li key={i} className="m10">{doc}</li>
              ))
            }
          </ul>

          <p className="red">
            TOUS LES DOCUMENTS DOIVENT ETRE TRANSMIS SUR CLEF USB OU ENVOYES PAR MAIL A 
            <a href="mailto:hiriburucambo64@yahoo.com" className="ml5">hiriburucambo64@yahoo.com</a>
          </p>
        </Jumbotron>

        <h1>Liens utiles</h1>
        <div className="mt20 f-row f-around f-wrap txt-c">
          <a
            href="https://www.icicode.fr/vues/acces_eleve/login.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5>Exercez vous au code en ligne</h5>
            <img src={require('../../assets/img/icicode.png')} alt="logo ICI CODE" className="logo-presta mb20" />
          </a>

          <a
            href="https://www.securite-routiere.gouv.fr/resultats-du-permis-de-conduire#/step-connexion"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5>Resultat permis de conduire</h5>
            <img src={require('../../assets/img/respermis.png')} alt="résultats permis" className="logo-presta mb20" />
          </a>

          <a
            href="https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour/definition-du"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5>Permis à 1 euro par jour</h5>
            <img src={require('../../assets/img/photos/1euro.png')} alt="logo 1€/jour" className="logo-presta mb20" />
          </a>

          <a
            href="https://www.facebook.com/Hiriburu-Conduite-1607180319335194/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5>Facebook</h5>
            <img src={require('../../assets/img/Facebook-c.svg')} alt="facebook" className="logo-presta mb20" />
          </a>
        </div>

        <Jumbotron className="mt40 p5">
          <More />
        </Jumbotron>
      </div>
    );
  }
}
