import React from 'react';
import {Button} from 'react-bootstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faAt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

function ActionBtn({type, value, width, txt}) {
  switch (type) {
    case 'phone':
      return (
        <div className={"mt10 " + width}>
          <a href={"tel:" + value}>
            <Button variant="success" className="w100 p3">
              <Icon icon={faPhoneAlt} />
              <span className={"ml5 white space-nowrap " + txt}>
                {value}
              </span>
            </Button>
          </a>
        </div>
      );

    case 'email':
      return (
        <div className={"mt10 " + width}>
          <a href={"mailto:" + value}>
            <Button variant="danger" className="w100 p3">
              <Icon icon={faAt} />
              <span className={"ml5 white " + txt}>
                {value}
              </span>
            </Button>
          </a>
        </div>
      );

    default:
      return null;
  }
}

export default ActionBtn;
