import React from 'react';
import {Card, Button, Jumbotron} from 'react-bootstrap';
// import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
// import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import data from './agencies';

let piste = (
  <div>
    <h5 className="blue mt20">LE PLUS CHEZ HIRIBURU CONDUITE</h5>
    <p>
      Pour toutes nos formations 2 roues, nous disposons d'une piste homologuée 
      privée afin d'assurer des formations performantes et sécurisées.
    </p>
  </div>
);

let raff = (
  <a href="https://raffmoto.fr/">
    <Jumbotron className={'f-between p20'}>
      <img src={require('../assets/img/raff-logo.png')} alt="logo Raff Moto" className="w20" />
      <p className="ml20 w80">Jusqu'à 15% de réduction chez notre partenaire <span className="txt-bold">Raff Moto</span> à Anglet</p>
    </Jumbotron>
  </a>
)

let codeR = (
  <div className="ml20">
    <h5 className="mb10">HORAIRES DES COURS DE CODE</h5>
    <h5>Cambo</h5>
    <p>{data.kanbo.code_times}</p>
    <h5>Saint Pierre d'Irube</h5>
    <p>{data.hiriburu.code_times}</p>
    <h5 className="mt20 mb10">EXERCEZ VOUS EN LIGNE</h5>
    <a className="mb40" href="https://www.icicode.fr/vues/acces_eleve/login.php" target="_blank" rel="noopener noreferrer">
      <img src={require('../assets/img/icicode.png')} alt="logo ICI CODE" className="logo-presta mr5" />
    </a>
  </div>
);

let condAcc = (
  <div className="ml20">
    <h5 className="mb10">CONDITIONS ET DEROULEMENT</h5>
    <h6>Pour l'élève:</h6>
    <ul className="mb10">
      <li>15 ans minimum</li>
      <li>Evaluation​​​​​​</li>
      <li>Obtention du code</li>
      <li>20h de conduite minimum légal avec un enseignant de la conduite</li>
      <li>RDV Préalable de départ</li>
      <li>RDV Pédagogiqe des 1000 km</li>
      <li>RDV Pédagogique des 2000 km</li>
      <li>Fin des 3000km (ou plus): présentation au permis.</li>
    </ul>
    <h6>Pour l'accompagnateur:</h6>
    <ul className="mb10">
      <li>5ans de permis sans interruption ( invalidation, suspension, annulation)</li>
      <li>Pas de casier judiciaire​​​​​​</li>
      <li>Présence constante: aux 3 RDV Pédagogique et durant toute la phase de la conduite accompagnée</li>
      <li>Accord de l'assurance</li>
    </ul>
    <h5 className="mb10">PERMIS A 1€/JOUR</h5>
    <a className="mb40" href="https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour/definition-du" target="_blank" rel="noopener noreferrer">
      <img src={require('../assets/img/1euro-2.png')} alt="logo 1€/jour" className="logo-presta mr5" />
      Conditions
    </a>
  </div>
);

let moreCA = (
  <div className="txt-l mt40">
    <h3 className="green mb10">PRENEZ LE VOLANT DES 15 ANS</h3>
    <p>
      Vous cherchez à gagner en expérience avant vos 18 ans pour être plus 
      à l’aise le jour du permis ? La conduite accompagnée est faite pour vous !
    </p>
    <p>
      Près de Bayonne, Hiriburu Conduite vous propose une formule de conduite 
      accompagnée avec les meilleurs moniteurs.
    </p>

    <div className={window.innerWidth <= 992 ? 'mt20' : 'f-between mt20'}>
      <Card className={window.innerWidth <= 992 ? 'w100' : 'w48'}>
        <Card.Img variant="top" src={require('../assets/img/photos/autoecole5.jpg')} />
        <Card.Body>
          <Card.Title>QU’EST CE QUE LA CONDUITE ACCOMPAGNÉE ?</Card.Title>
          <Card.Text>
            Depuis 2014, la conduite accompagnée permet aux élèves de plus de 15 ans de 
            commencer à s’exercer à la conduite avec la possibilité de passer le permis dès 17 ans (avec 1 an de conduite minimum obligatoire). Sur une période d’1 à 3 ans, après un 
            minimum de 20 heures de cours de conduite, vous aurez la possibilité de continuer 
            à conduire avec un accompagnateur de votre choix. Votre accompagnateur doit avoir 
            son permis depuis plus de 5 ans.
          </Card.Text>
          <Card.Text>
            Dans la région de Bayonne, Hiriburu Conduite vous propose une formule de conduite 
            accompagnée adaptée aux jeunes conducteurs et aux lycéens. Nous proposons des cours 
            de code en soirée et le samedi matin pour nous adapter à vos agendas.
          </Card.Text>
          <Card.Text>
            Si vous avez plus de 18 ans et souhaitez passer votre en formation accélérée de conduite.
          </Card.Text>
          <Card.Text>
            GRÂCE À LA CONDUITE ACCOMPAGNÉE, METTEZ TOUTES LES CHANCES DE VOTRE COTÉ POUR L’EXAMEN DE VOTRE PERMIS
          </Card.Text>
          <Card.Title>POURQUOI FAIRE SA CONDUITE ACCOMPAGNÉE CHEZ HIRIBURU CONDUITE ?</Card.Title>
          <ul>
            <li>30 ans d’expérience au service des élèves</li>
            <li>Des horaires adaptées aux emploi du temps des lycéens</li>
            <li>Des tarifs compétitifs</li>
            <li>Un suivi durant toute la période de conduite accompagnée</li>
          </ul>
        </Card.Body>
      </Card>

      <Card className={window.innerWidth <= 992 ? 'w100' : 'w48'}>
        <Card.Img variant="top" src={require('../assets/img/photos/autoecole6.jpg')} />
        <Card.Body>
          <Card.Title className="mb20">POURQUOI OPTEZ POUR LA CONDUITE ACCOMPAGNÉE ?</Card.Title>
          <Card.Text>
            Les statistiques parlent d’elle même, les élèves qui optent pour la conduite 
            accompagnée ont en moyenne 30% de chance de plus de réussite à l’examen de votre permis.
          </Card.Text>
          <Card.Text>
            Grâce à l’expérience acquise durant les 2 ans de formations, les élèves sont plus aguerris 
            et plus avertis aux dangers de la route. En effet, les conducteurs qui sont passés par la 
            conduite accompagnée ont beaucoup moins d’accidents que les autres conducteurs.
          </Card.Text>
          <Card.Text>
            Les assurances, conscientes de l’expérience apportée, proposent des tarifs préférentiels et 
            votre période probatoire est uniquement de 2 ans. Durant les 2 ans de formation, 
            Hiriburu Conduite vous garantie un suivi régulier. Nous serons vous conseiller et répondre 
            à tous vos doutes concernant l’apprentissage de la conduite et ainsi maximise ainsi vos 
            chances de succès à l’examen du permis de conduire.
          </Card.Text>
          <Card.Text>
            AU LIEU DES 3 ANS OBLIGATOIRES, LA CONDUITE ACCOMPAGNÉE PERMET DE RÉDUITE LA PÉRIODE PROBATOIRE À 2 ANS
          </Card.Text>
          <Card.Title>PRENEZ CONTACT AVEC NOUS</Card.Title>
          <Card.Link href="/contact">
            <Button variant="success" className="w100 mt20">CONTACT</Button>
          </Card.Link>
        </Card.Body>
      </Card>
    </div>
  </div>
)

let condSup = (
  <div className="ml20">
    <h5 className="mb10">CONDITIONS ET DEROULEMENT</h5>
    <h6>Pour l'élève:</h6>
    <ul className="mb10">
      <li>18 ans minimum</li>
      <li>Evaluation​​​​​​</li>
      <li>Obtention du code</li>
      <li>20h de conduite minimum légal avec un enseignant de la conduite</li>
      <li>RDV Préalable de départ</li>
      <li>Présentation au permis de conduire</li>
    </ul>
    <h6>Pour l'accompagnateur:</h6>
    <ul className="mb10">
      <li>5ans de permis sans interruption ( invalidation, suspension, annulation)</li>
      <li>Accord de l'assurance</li>
    </ul>
    <h5 className="mb10">PERMIS A 1€/JOUR</h5>
    <a className="mb40" href="https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour/definition-du" target="_blank" rel="noopener noreferrer">
      <img src={require('../assets/img/1euro-2.png')} alt="logo 1€/jour" className="logo-presta mr5" />
      Conditions
    </a>
  </div>
);

let pB = (
  <div className="ml20">
    <h5 className="mb10">CONDITIONS ET DEROULEMENT</h5>
    <h6>Pour l'élève:</h6>
    <ul className="mb10">
      <li>18 ans minimum</li>
      <li>Evaluation​​​​​​</li>
      <li>Obtention du code</li>
      <li>20h de conduite minimum légal avec un enseignant de la conduite</li>
      <li>Présentation au permis de conduire</li>
    </ul>
    <h5 className="mb10">PERMIS A 1€/JOUR</h5>
    <a className="mb40" href="https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour/definition-du" target="_blank" rel="noopener noreferrer">
      <img src={require('../assets/img/1euro-2.png')} alt="logo 1€/jour" className="logo-presta mr5" />
      Conditions
    </a>
    <h5 className="mb10 mt20">RESULTATS DU PERMIS DE CONDUIRE</h5>
    <a className="mb40" href="https://www.securite-routiere.gouv.fr/resultats-du-permis-de-conduire#/step-connexion" target="_blank" rel="noopener noreferrer">
      <img src={require('../assets/img/respermis.png')} alt="résultats permis" className="logo-presta mr5" />
      Consulter mes résultats
    </a>
  </div>
);

let pBSR = (
  <div className="ml20">
    <h5 className="mb10">CONDITIONS ET DEROULEMENT</h5>

    <h6>1ere Demi journée : 4 Heures</h6>
    <ul>
      <li>Apprentissage de la conduite hors circulation sur circuit privé</li>
      <li>Prise en main du scooter​​​​​​</li>
    </ul>
    <h6>2eme Demi journée : 4 Heures</h6>
    <ul>
      <li>Apprentissage de la conduite en circulation </li>
      <li>Bilan de fin formation​​​​​​</li>
    </ul>

    <h5>EQUIPEMENT:</h5>
    <p>
      Casque homologué munit de 4 dispositifs réfléchissants avec visière ou lunette, 
      gants homologués, chaussures fermées montantes, pantalon et blouson (prévoir 
      équipement de pluie selon météo).
    </p>

    {raff}

    {piste}
  </div>
);

let p125 = (
  <div className="ml20">
    <h5 className="mb10">CONDITIONS ET DEROULEMENT</h5>
    <h6>DEROULE:</h6>
    <ul className="mb20">
      <li>Formation de 7 heures : hors circulation + en circulation</li>
      <li>Possibilité de faire la formation sur scooter ou moto 125​​​​​​</li>
    </ul>
    <h5 className="mb10">RESULTATS DU PERMIS DE CONDUIRE</h5>
    <a className="mb40" href="https://www.securite-routiere.gouv.fr/resultats-du-permis-de-conduire#/step-connexion" target="_blank" rel="noopener noreferrer" >
      <img src={require('../assets/img/respermis.png')} alt="résultats permis" className="logo-presta mr5" />
      Consulter mes résultats
    </a>

    <h6>EQUIPEMENT:</h6>
    <p>
      Casque homologué munit de 4 dispositifs réfléchissants avec visière ou lunette, 
      gants homologués, chaussures fermées montantes, pantalon et blouson (prévoir 
      équipement de pluie selon météo).
    </p>

    {raff}
    
    {piste}
  </div>
);

// let pA1 = (
//   <div className="ml20">
//     <h5 className="mb10">CONDITIONS ET DEROULEMENT</h5>
//     <ul className="mb10">
//       <li>16 ans minimum</li>
//       <li>Evaluation​​​​​​</li>
//       <li>Obtention du code</li>
//       <li>20h de conduite minimum légal avec un enseignant de la conduite</li>
//     </ul>
//     <h6>EQUIPEMENT:</h6>
//     <p className="mb10">
//       Casque homologué munit de 4 dispositifs réfléchissants avec visière ou lunette, 
//       gants homologués, chaussures fermées montantes, pantalon et blouson (prévoir 
//       équipement de pluie selon météo).
//     </p>

//     {raff}

//     <h5 className="mb10">PERMIS A 1€/JOUR</h5>
//     <a className="mb40" href="https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour/definition-du" target="_blank" rel="noopener noreferrer" >
//       <img src={require('../assets/img/photos/1euro.png')} alt="logo 1€/jour" className="logo-presta mr5" />
//       Conditions
//     </a>
//     <h5 className="mb10 mt20">RESULTATS DU PERMIS DE CONDUIRE</h5>
//     <a className="mb40" href="https://www.securite-routiere.gouv.fr/resultats-du-permis-de-conduire#/step-connexion" target="_blank" rel="noopener noreferrer" >
//       <img src={require('../assets/img/respermis.png')} alt="résultats permis" className="logo-presta mr5" />
//       Consulter mes résultats
//     </a>
    
//     {piste}
//   </div>
// );

// let pA2 = (
//   <div className="ml20">
//     <h5 className="mb10">CONDITIONS ET DEROULEMENT</h5>
//     <ul className="mb10">
//       <li>18 ans minimum</li>
//       <li>Evaluation​​​​​​</li>
//       <li>Obtention du code</li>
//       <li>20h de conduite minimum légal avec un enseignant de la conduite (15 si vous avez le A1)</li>
//     </ul>
//     <h6>EQUIPEMENT:</h6>
//     <p className="mb10">
//       Casque homologué munit de 4 dispositifs réfléchissants avec visière ou lunette, 
//       gants homologués, chaussures fermées montantes, pantalon et blouson (prévoir 
//       équipement de pluie selon météo).
//     </p>

//     {raff}

//     <h5 className="mb10">PERMIS A 1€/JOUR</h5>
//     <a className="mb40" href="https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour/definition-du" target="_blank" rel="noopener noreferrer" >
//       <img src={require('../assets/img/photos/1euro.png')} alt="logo 1€/jour" className="logo-presta mr5" />
//       Conditions
//     </a>
//     <h5 className="mb10 mt20">RESULTATS DU PERMIS DE CONDUIRE</h5>
//     <a className="mb40" href="https://www.securite-routiere.gouv.fr/resultats-du-permis-de-conduire#/step-connexion" target="_blank" rel="noopener noreferrer" >
//       <img src={require('../assets/img/respermis.png')} alt="résultats permis" className="logo-presta mr5" />
//       Consulter mes résultats
//     </a>
    
//     {piste}
//   </div>
// );

let postP = (
  <div className="ml20">
    <h5>JEUNES CONDUCTEURS : UNE REDUCTION DE VOTRE PERIODE PROBATOIRE ?</h5>
    <p>
      LES JEUNES CONDUCTEURS QUI LE SOUHAITES POURRONT SUIVRE UNE FORMATION DE 7H ENTRE 6 MOIS ET 1 AN 
      DE PERMIS. EN PLUS D'OFFRIR UNE PIQURE DE RAPPEL EN MATIERE DE SECURITE ROUTIERE, CETTE FORMATION 
      LEUR PERMETTRA DE REDUIRE DE 6 MOIS A 1 AN LEUR PERDIODE PROBATOIRE.
    </p>
    <h6>A QUI EST OUVERTE CETTE FORMATION ?</h6>
    <p>
      Les conducteurs ayant obtenue leur permis A1, A2, B1 et B (obtenue en conduite accompagnée, 
      supervisée ou traditonnelle), pourront suivre cette foration entre 6 mois et 1 an de permis. 
    </p>
    <h6>A QUOI VA SERVIR CETTE FORMATION ?</h6>
    <p>
      La Sécurité routière souhaite construire, par cette formation, un état de conscience auprès 
      des jeunes conducteurs. Le but est de lutter contre le phénomène de "sur-confiance" qui est 
      présent aprés la première année de permis en invitant les apprentis a mieux appréhender les 
      risques sur la route et à éviter les accidents.
    </p>
    <h6>VOUS ETES INTERESSE PAR LA FORMATION ?</h6>
    <p>
      Les premiers bénéficiaires de cette formation sont les personnes ayant obtenue leur permis a partir 
      de février 2018. Soyez sur de demander cette formation complémentaire entre les 6 mois et 1 an après 
      l'obtention de votre permis de conduire. A ce jour, ni le tarif, ni le détail de la formation n'ont 
      encore été définis. 
    </p>
  </div>
);

let ran = (
  <div className="ml20">
    <h5>LA REMISE À NIVEAU DE CONDUITE</h5>
    <p>
      Vous n’avez pas conduit depuis longtemps et craignez de reprendre la route ? Pas de panique, 
      Hiriburu Conduite vous offre des formations de remise à niveau.
    </p>
    <p>
      Hiriburu Conduite est une école de conduite implantée près de Bayonne et qui vous offre des 
      formations de remise à niveau pour la conduite.
    </p>
    <h6>CORRIGEZ VOS MAUVAIS RÉFLEXES DE CONDUITE</h6>
    <p>
      Que vous n’avez pas conduit depuis longtemps ou que vous souhaitez remettre à jour vos connaissances 
      en terme de sécurité routière est de conduite, les stages de remises à niveau sont fait pour vous.
    </p>
    <p>
      L’auto école Hiriburu Conduite vous propose plusieurs formules de remises à niveau qui s’adapteront 
      à vos demandes. Ces stages comprennent à la fois une partie théorique. En effet, le code de la route 
      est en permanente évolution et il est recommandable de faire le point sur les nouvelles règles de 
      manière régulière.
    </p>
    <p>
      Hiriburu Conduite propose également une remise à niveau pratique avec des heures de conduites que vous 
      pouvez achetez. Nos moniteurs expérimentés seront faire le point avec vous sur votre conduite et vous 
      aideront à corriger vos mauvais réflexes et à garantir votre sécurité sur la route.
    </p>

    <h6>LA REMISE À NIVEAU EST LA SOLUTION IDÉALE POUR VOTRE SÉCURITÉ SUR LA ROUTE APRÈS UNE LONGUE PÉRIODE SANS CONDUIRE.</h6>
  </div>
);

let boiteAuto = (
  <div className="ml20">
    <h5 className="mb10">PERMIS BOITE AUTOMATIQUE</h5>
    <ul className="mb10">
      <li>18 ans minimum</li>
      <li>Evaluation​​​​​​</li>
      <li>Obtention du code</li>
      <li>13h de conduite minimum légal avec un enseignant de la conduite</li>
      <li>Présentation au permis de conduire</li>
    </ul>
    <h5 className="mb10">PERMIS A 1€/JOUR</h5>
    <a className="mb40" href="https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour/definition-du" target="_blank" rel="noopener noreferrer">
      <img src={require('../assets/img/1euro-2.png')} alt="logo 1€/jour" className="logo-presta mr5" />
      Conditions
    </a>
    <h5 className="mb10 mt20">RESULTATS DU PERMIS DE CONDUIRE</h5>
    <a className="mb40" href="https://www.securite-routiere.gouv.fr/resultats-du-permis-de-conduire#/step-connexion" target="_blank" rel="noopener noreferrer">
      <img src={require('../assets/img/respermis.png')} alt="résultats permis" className="logo-presta mr5" />
      Consulter mes résultats
    </a>
  </div>
);

export default {
  inscriptionDocs: [
    '1 photocopie de la carte d’identité (recto-verso) + celle d’un représentant légal si mineur',
    '1 justificatif de domicile (facture de - de 6 mois) ou attestation d\'hébergement',
    '4 photos d’identité, format Ephoto',
    '1 photocopie de l’ASSR (2ème niveau)',
    '1 photocopie du permis actuel (pour les candidats ayant déjà un permis)',
    'Pour les candidats âgés de 17 ans à 25 ans non révolus: copie du certificat individuel de participation à la JDC ou OU copie attestation provisoire en instance de convocation à la JDC OU copie de l\'attestation individuelle d’exemption',
  ],

  // colors: [#f54e42, #f59342, #f5c842, #b9f542, #42f598, #42f5e9, #4281f5, #e342f5],

  code: {
    title: 'Code de la route',
    body: codeR,
    icon: require('../assets/img/code.png'),
    img: require('../assets/img/photos/autoecole7.jpg'),
    color: 'dark',
    bgColor: 'red',
  },
  
  condAcc: {
    title: 'Conduite accompagnée',
    body: condAcc,
    icon: require('../assets/img/CA.png'),
    img: require('../assets/img/photos/car1.jpg'),
    color: 'dark',
    bgColor: 'blue',
    more: moreCA,
  },
  
  condSup: {
    title: 'Conduite supervisée',
    body: condSup,
    icon: require('../assets/img/CS.png'),
    img: require('../assets/img/photos/cars5.jpg'),
    color: 'dark',
    bgColor: 'green',
  },
  
  pB: {
    title: 'Permis B',
    body: pB,
    icon: require('../assets/img/car.svg'),
    img: require('../assets/img/photos/cars6.jpg'),
    color: 'dark',
    bgColor: 'orange',
  },
  
  pBSR: {
    title: 'Permis AM / BSR',
    body: pBSR,
    icon: require('../assets/img/scoot.svg'),
    img: require('../assets/img/photos/bsr.jpg'),
    color: 'dark',
    bgColor: 'turquoise',
  },
  
  p125: {
    title: 'Formation 125',
    body: p125,
    icon: require('../assets/img/scooter125.svg'),
    img: require('../assets/img/photos/125.jpg'),
    color: 'dark',
    bgColor: 'cyan',
  },
  
  // pA1: {
  //   title: 'Permis A1',
  //   body: pA1,
  //   icon: require('../assets/img/A1.png'),
  //   img: require('../assets/img/photos/moto1.jpg'),
  //   color: 'dark',
  //   bgColor: 'purple',
  // },
  
  // pA2: {
  //   title: 'Permis A2',
  //   body: pA2,
  //   icon: require('../assets/img/moto.svg'),
  //   img: require('../assets/img/photos/piste-moto.jpg'),
  //   color: 'dark',
  //   bgColor: 'red',  },
  
  postP: {
    title: 'Post Permis',
    body: postP,
    icon: require('../assets/img/gauge.svg'),
    img: require('../assets/img/photos/salle.jpg'),
    color: 'dark',
    bgColor: 'yellow',
  },

  ran: {
    title: 'Remise A Niveau',
    body: ran,
    icon: require('../assets/img/volant.svg'),
    img: require('../assets/img/photos/cars6.jpg'),
    color: 'dark',
    bgColor: 'blue',
  },

  boiteAuto: {
    title: 'Permis B, boîte automatique',
    body: boiteAuto,
    icon: require('../assets/img/bauto.png'),
    img: require('../assets/img/photos/car1.jpg'),
    color: 'dark',
    bgColor: 'orange',
  }
};
