import React from 'react';
// import ReactDOM from 'react-dom';
import { render } from 'react-snapshot';
import App from './app/App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/app.scss';

// ReactDOM.render(
render(
  <App id="App" />,document.getElementById('root')
);
