export default {
  shared: {
    phone: '09 54 72 03 54',
    email: 'hiriburucambo64@yahoo.com',
    facebook: 'https://www.facebook.com/Hiriburu-Conduite-1607180319335194/',
  },

  hiriburu: {
    img: require('../assets/img/photos/office.JPG'),
    phone: '09 54 72 03 54',
    email: 'hiriburucambo64@yahoo.com',
    street: '9 avenue Harrokan',
    postal: '64990',
    city: 'Saint Pierre d\'Irube',
    accueil_times: 'L\'accueil du public se fait uniquement au bureau de Cambo, par mail ou téléphone.',
    driving_times: 'Du Lundi au Samedi de 08h à 20h. Autorisation pour l’élève ou l’enseignant de se déplacer après 18h à l\'occasion d\'un cours de conduite ou pour rentrer à son domicile à l\'issue de ce cours.',
    code_times: 'Conformément au protocole sanitaire en vigueur, les cours théoriques en présentiel restent interdits jusqu\'à nouvel ordre.',
    localisation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2895.898284377027!2d-1.440778909436278!3d43.46272068756841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5138b2536c8c63%3A0xd4037e1033b265a1!2sHiriburu%20conduite%20%7C%20Durruty%20Michele%20%7C%20auto%20%C3%A9cole!5e0!3m2!1sfr!2sfr!4v1573123293589!5m2!1sfr!2sfr',
  },
  
  kanbo: {
    img: require('../assets/img/photos/office2.jpg'),
    phone: '09 54 72 03 54',
    email: 'hiriburucambo64@yahoo.com',
    street: '3 allée des marronniers',
    postal: '64250',
    city: 'Cambo les bains',
    accueil_times: 'Le secrétariat de Cambo-les-bains est ouvert le mardi de 10h à 12h et de 14h à 18h00. En dehors des heures d\'ouvertures contactez-nous par mail.',
    driving_times: 'Du Lundi au Samedi de 08h à 20h. Autorisation pour l’élève ou l’enseignant de se déplacer après 18h à l\'occasion d\'un cours de conduite ou pour rentrer à son domicile à l\'issue de ce cours.',
    code_times: 'Conformément au protocole sanitaire en vigueur, les cours théoriques en présentiel restent interdits jusqu\'à nouvel ordre.',
    localisation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2900.7254585507485!2d-1.404215132266477!3d43.36185301052972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd513a5520f7783b%3A0xcb3433b3521e9ac0!2s3%20All%C3%A9e%20des%20Marronniers%2C%2064250%20Cambo-les-Bains!5e0!3m2!1sfr!2sfr!4v1573123563029!5m2!1sfr!2sfr',
  },
};
